import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueMatomo from 'vue-matomo';
import VueMeta from 'vue-meta'


import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faCalendar,
  faCheck,
  faEnvelope,
  faFilter,
  faMapMarker,
  faTimes,
  faUserCircle,
  faUserSecret
} from '@fortawesome/free-solid-svg-icons'
import {faInstagram, faSpotify, faYoutube} from '@fortawesome/free-brands-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import dayjs from 'dayjs'
import 'dayjs/locale/de'

Vue.config.productionTip = false

const cgRestApi = axios.create({
  baseURL: 'https://rest.cg-karlsruhe.de/calendar'
  //baseURL: 'http://localhost:8081'
})

const cgMailApi = axios.create({
  baseURL: 'https://rest.cg-karlsruhe.de/mail'
   //baseURL: 'http://localhost:8090'
})

Vue.prototype.$cgrest = cgRestApi;
Vue.prototype.$cgMailApi = cgMailApi;

library.add(faUserSecret, faUserCircle, faCheck, faTimes, faFilter, faCalendar, faMapMarker, faEnvelope, faYoutube, faInstagram, faSpotify)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Buefy);
Vue.use(VueMeta);

Vue.use(VueMatomo, {
  host: "https://matomo.cg-karlsruhe.de",
  siteId: 3,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: false,
  disableCookies: true,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});

//TODO use instead https://www.npmjs.com/package/vue-truncate
var filter = function(text, length, clamp){
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

/**** DayJs Filter **/
var dayFilter = function(datum, formatStyle){
  return dayjs(datum).locale("de").format(formatStyle);
};

Vue.filter('dayFilter', dayFilter);


router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

new Vue({
  router,
  created() {
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', 'de')
  },
  render: h => h(App)
}).$mount('#app')
