<template>

    <header class="header ">
        <div id="quick_navigation" class="is-hidden-touch">

            <router-link to="/veranstaltungen"><font-awesome-icon icon="calendar" style="margin-right:2px;"/> Zum Kalender</router-link>

            <a class="" href="https://goo.gl/maps/NpEinmSXAb22">
                <font-awesome-icon icon="map-marker" style="margin-left:15px;  margin-right:2px;"/>
                Finde uns bei Google Maps
            </a>

            <router-link to="/kontakt"><font-awesome-icon icon="envelope" style="margin-left:15px;  margin-right:2px;"/>
                Erreiche uns per E-Mail</router-link>

            <a class="" href="https://live.cg-karlsruhe.de">
                <font-awesome-icon :icon="['fab', 'youtube']"  style="margin-left:15px;  margin-right:2px;"/>
                Wir auf YouTube
            </a>

          <a class="" href="https://www.instagram.com/cg_karlsruhe/">
            <font-awesome-icon :icon="['fab', 'instagram']"  style="margin-left:15px;  margin-right:2px;"/>
            Wir auf Instagram
          </a>

            <a class="" href="http://spotify.cg-karlsruhe.de/">
                <font-awesome-icon :icon="['fab', 'spotify']"  style="margin-left:15px;  margin-right:2px;"/>
                Wir auf Spotify
            </a>
        </div>

        <div class="container">
            <nav class="level is-hidden-touch">
                <div class="level-item" style="margin-top:20px">
                    <router-link to="/" class="logo"><img src="../assets/CGK-logo-horiz-farbig.png" width="150">
                    </router-link>

                    <ul class="menu">
                        <li>
                            <router-link to="/about" active-class="active">ÜBER UNS</router-link>
                        </li>
                        <li>
                            <router-link to="/veranstaltungen" active-class="active">VERANSTALTUNGEN</router-link>
                        </li>
                        <li>
                            <router-link to="/gruppen" active-class="active">GRUPPEN</router-link>
                        </li>
                        <li>
                            <router-link to="/kontakt" active-class="active">KONTAKT</router-link>
                        </li>
                        <li>
                            <router-link to="/impressum" active-class="active">IMPRESSUM</router-link>
                        </li>
                        <li><a href="https://intern.cg-karlsruhe.de">
                            <font-awesome-icon icon="user-circle"/>
                            INTERN</a></li>
                    </ul>
                </div>
            </nav>


            <div class="container is-hidden-desktop">
                <router-link to="/" class="logo"><img src="../assets/CGK-logo-horiz-farbig.png" width="200">
                </router-link>

                <input class="menu-btn" type="checkbox" id="menu-btn"/>
                <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>

                <ul class="menu">
                    <li>
                        <router-link to="/about">ÜBER UNS</router-link>
                    </li>
                    <li>
                        <router-link to="/veranstaltungen">VERANSTALTUNGEN</router-link>
                    </li>
                    <li>
                        <router-link to="/gruppen">GRUPPEN</router-link>
                    </li>
                    <li>
                        <router-link to="/kontakt">KONTAKT</router-link>
                    </li>
                    <li>
                        <router-link to="/impressum">IMPRESSUM</router-link>
                    </li>
                    <li><a href="http://intern.cg-karlrsuhe.de">
                        <font-awesome-icon icon="user-circle"/>
                        INTERN</a></li>
                </ul>
            </div>

        </div>
    </header>
</template>

<script>
    export default {
        name: 'Menu',
        props: {
            title: String,
            subtitle: String,
            image: String
        },
        watch: {
            '$route'() {
                document.getElementById("menu-btn").checked = false;
            }
        }

    }
</script>

<style>

    /* header */
    .header {
        background-color: #fffaec;
        position: fixed;
        width: 100%;
        z-index: 3;
    }

    .header ul {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        background-color: #fffaec;
    }

    .header li a {
        display: block;
        padding-left: 10px;
        padding-right: 10px;
        text-decoration: none;
        color: #58595B;
        margin-right: 20px;
        font-weight: bold;
    }

    .header li a.active {
        display: block;
        text-decoration: none;
        border-bottom: 5px solid #FAA61A;
        color: #FAA61A;
    }

    .header li a:hover,
    .header .menu-btn:hover {
        background-color: #fffaec;
    }

    .header .logo {
        display: block;
        float: left;
        font-size: 2em;
        padding: 10px 20px;
        text-decoration: none;
    }

    /* menu */

    .header .menu {
        clear: both;
        max-height: 0;
        transition: max-height .2s ease-out;
    }

    /* menu icon */

    .header .menu-icon {
        cursor: pointer;
        display: inline-block;
        float: right;
        padding: 28px 20px;
        position: relative;
        user-select: none;
    }

    .header .menu-icon .navicon {
        background: #333;
        display: block;
        height: 2px;
        position: relative;
        transition: background .2s ease-out;
        width: 18px;
    }

    .header .menu-icon .navicon:before,
    .header .menu-icon .navicon:after {
        background: #333;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all .2s ease-out;
        width: 100%;
    }

    .header .menu-icon .navicon:before {
        top: 5px;
    }

    .header .menu-icon .navicon:after {
        top: -5px;
    }

    /* menu btn */

    .header .menu-btn {
        display: none;
    }

    .header .menu-btn:checked ~ .menu {
        max-height: 250px;
    }

    .header .menu-btn:checked ~ .menu-icon .navicon {
        background: transparent;
    }

    .header .menu-btn:checked ~ .menu-icon .navicon:before {
        transform: rotate(-45deg);
    }

    .header .menu-btn:checked ~ .menu-icon .navicon:after {
        transform: rotate(45deg);
    }

    .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
    .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
        top: 0;
    }

    @media (min-width: 1024px) {
        .header li {
            float: left;
        }

        .header li a {
            padding-top: 15px;
            font-size: 15pt;
            padding-right: 5px;
        }

        .header .menu {
            clear: none;
            float: left;
            max-height: none;
        }

        .header .menu-icon {
            display: none;
        }
    }



    @media only screen and (max-width: 1216px) {
      .is-hidden-touch li a {
        font-size: 12pt;
      }
    }

    @media only screen and (max-width: 1024px) {
        #quick_navigation {
            display: none;
        }
    }

    #quick_navigation {
        background-color:#ffc20e;
        text-align: center;
        height:30px;
        padding-top:5px;
    }

    #quick_navigation a {
        color: white;
    }
</style>